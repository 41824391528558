<template>
  <div v-show="invoice!=null">
    <b-modal
        id="invoiceUpdate"
        centered hide-footer
        :title="invoice.title"
        ref="invoiceUpdate"
    >
      <CustomLoader :show="isLoading"/>
      <b-card-text class="text-center">
        <strong>
          {{invoice.company.name }}
        </strong>
      </b-card-text>
      <b-form-group
          id="issuer-group"
          :label="$t('issuer') + ':'"
          label-for="issuer">
        <b-form-select
            id="issuer"
            v-model="invoice.issuer_id"
            required
        >
            <option v-for="(item,index) in companies" :value="item.id" :key="index">{{item.name}}</option>
        </b-form-select>
      </b-form-group>
      <hr />
      <b-row>
        <b-col class="text-center">
          <b-button variant="danger" @click="$bvModal.hide('invoiceUpdate')" class="mr-2">
            <feather-icon icon="XIcon"></feather-icon>
          </b-button>
          <b-button variant="primary" @click="updateInvoice(invoice.id)">
            <feather-icon icon="CheckIcon"></feather-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton,  BCardText, BRow, BCol, BFormGroup, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CustomLoader from "@/components/Common/CustomLoader";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  name: "InvoiceUpdate",
  components: {
    CustomLoader,
    BButton,BModal, BCardText, BRow, BCol, BFormGroup, BFormSelect
  },
  directives: {
    Ripple,
  },
  props: ['invoice', 'companies'],
  data(){
    return {
      isLoading: false,
    }
  },
  methods: {
    updateInvoice(id){
        this.isLoading = true;
        this.$http.post('/invoice/admin/update/'+id,
            this.invoice
        ).then(response => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('updated_successfully'),
              
              icon: "SuccessIcon",
              variant: "success",
            },
          });
        }).catch(error => {
          this.isLoading = false;
        })
    }
    // getInvoice(id){
    //   this.isLoading = true;
    //   this.invoice = null;
    //   this.$http.post('/invoice/admin/show/'+id).then(response => {
    //     if (response.data.status == 200) {
    //       this.invoice = response.data.value
    //     }
    //     this.isLoading = false;
    //   }).catch(error => {
    //     this.isLoading = false;
    //   })
    // }
  },
  watch:{

  },
  created(){
    //this.getInvoice(this.id);
  }
}
</script>

<style scoped>

</style>
