<template>
    <b-card>
      <b-row class="">
        <b-col class="col-md-12">
          <h2
            class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1"
          >
            {{ $t("ip_logins") }}
          </h2>
        </b-col>
      </b-row>
      <Table
        large
        :fields="fields"
        :url="url"
        :showSearch="true"
        :body="{}"
        responsive="sm"
      >
        <template #cell(id)="data">
          <b-link style="width:55px;" class="badge badge-light-primary">{{ data.item.id }}</b-link>
        </template>
        <template #cell(ip)="data">
          <b-link
            >{{ data.item.ip }} 
          </b-link>
        </template>
        <template #cell(user)="data">
            <b-link
              >{{ data.item.user.name }} {{ data.item.user.last_name }} <br> {{ data.item.user.email }} 
            </b-link>
          </template>
        <template #cell(timestamp)="data">
          <b-link 
            >{{data.item.created_at.substring(10,0)}}
          </b-link>
         
        </template>
       
  
      </Table>
  
    </b-card>
  </template>
  
  <script>
  import {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import fileDownload from "js-file-download";
  import ApproveModal from "@/components/Common/ApproveModal";
  import InvoiceUpdate from "@/components/Invoice/InvoiceUpdate";
  import CustomLoader from "@/components/Common/CustomLoader";
  import Table from "@/components/Common/Table2";
  import Ripple from "vue-ripple-directive";
  
  export default {
    name: "Invoices",
    components: {
      ApproveModal,
      Table,
      BTable,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BPagination,
      BTr,
      BTh,
      BButton,
      BLink,
      CustomLoader,
      InvoiceUpdate,
    },
    directives: {
      Ripple,
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    data() {
      return {
        title: "invoices_management",
        companies: [],
        modalText: null,
        modalTitle: null,
        options: null,
        fields1: [
          { label: "title", align: "start", key: "title", sortable: false },
          { label: "", customSlot: false, key: "custom", class: "text-right" },
        ],
        fields: [
          { label: "ID", align: "start", key: "id", sortable: true },
          { text: "User", align: "start", key: "user", sortable: true },
          { text: "IP", align: "start", key: "ip", sortable: false },
          { text: "Timestamp", align: "start", key: "timestamp", sortable: false },
          
    
        ],
        items: [],
        search: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 12,
        status: [],
        isLoading: false,
        totalItems: 0,
        updateInvoiceId: null,
        url: "/user/admin/last/login",
        approveModalAction: null,
        item: null,
        showPdfViwer: false,
        loadPdf: true,
        type: "",
        base64: "",
        source: null,
        embedType: "",
        src: "",
      };
    },
    created() {
      this.getApiData();
    },
    methods: {
  
     
      getApiData() {
        this.isLoading = true;
        this.items = [];
        this.$http
          .get("/user/admin/last/login?page=" + this.page, {
            status: this.status,
            options: null,
            filters: this.filters,
            search: this.search,
          })
          .then((response) => {
            if (response.data.status == 200) {
              this.items = response.data.value.data;
              this.totalItems = response.data.value.total;
              this.page = response.data.value.current_page;
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
          });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  